.card-container {
    width: 240px;
    height: 240px;
    padding-left: 12px;
    padding-right: 12px;
    margin: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
}

.hint-card {
    font-family: 'Helvetica';
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 140px;
    border-radius: 8px;
    perspective: 1400px;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    user-select: none;
}

.card-front,
.card-back {
    box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}

.card-front {
    background: #2196f3;
    background-repeat: 'repeat';
    background-position: 'centered';
    background-attachment: 'attached';
}

.card-back {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(180deg);
    color: #2196f3;
    background: #fff;
}

.card-dimmed {
    opacity: 0.25;
}